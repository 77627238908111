* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  padding: 2rem;
  background-color: whitesmoke;
}

button {
  margin: 2rem 0;
  padding: 1rem 2rem;
  width: 120px;
  cursor: pointer;
}

img {
  width: 120px;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
}